import React from 'react'

export default function Site() {
  return (
    <div className="site">
  

<div class="BeOpWidget" data-content="6284668f7e4d845fbcc42198"></div>
    </div>
  )
}

import React from 'react'


export default function Footer() {
  return (
    <div className="footer">
 
    <p>Settings <a href="https:/cloud.unlimitednow.live/login">Dashboard</a>  <a href="https://unlimitpotential.com/en">Subscriptions </a> Preferences <a href="https://cloud.unlimitednow.live/login">Login/ Signup</a><br> Copyright Unlimit Potential All Rights Reserved</br> </p>
    
     
    </div>
  )
}

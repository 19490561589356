export const FetchPostsApi = () => {
  return (dispatch) => {
          const Cosmic = require('cosmicjs')
          var api = new Cosmic();
          var bucket = api.bucket({
            slug: process.env.REACT_APP_COSMIC_BUCKET || 'f0775d10-d8fe-11ec-b6f9-b371ccb9f6b9',
            read_key: process.env.REACT_APP_COSMIC_READ_KEY || '4eyxppAarTO05XErlXNSrPrB9oRUeUKRSrlPPrbJOdXz6nBZMC'
          })
          bucket.getObjects().then(data => {          
          dispatch({type:"FETCH_POSTS", data:data});            
          }).catch(err => {
            console.log(err)
          })

  }
}